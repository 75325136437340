<template>
   <div>
       <img class="card-img-top" src="../img/Highlander_Interior_Carousel.jpg" style="height: auto" alt="Detailed interior">
       <h1 class="text-center text-danger" >Write a Review</h1>

       <form @submit.prevent="submitReview()">
           <div class="form-group col-6">
               <label for="myFName">Your Name:</label>
               <input type="text" class="form-control" placeholder="John" name="Your Name" id="myFName" v-model="reviews.reviewerName" required>
           </div>

           <div class="form-group col-6">
               <label for="myComments">Write your review</label>
               <textarea name="Write your review" class="form-control" v-model="reviews.reviewContent" id="myComments" rows="2" cols="30"></textarea>
               <input type="submit" class="mt-3" value="submit" onclick="submitReview()"/>
           </div>
       </form>
   </div>

</template>

<script>
    import {db} from "../main";

    export default {
        name: "Reviews",
        data(){
            return{
                reviews:{},

            }
        },
        methods:{
            submitReview(){
                // e.preventDefault();
                let highestId = 0;

                db
                    .collection("Reviews")
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            const thisReview = doc.data();
                            console.log("before",highestId,thisReview.reviewId);
                            if (thisReview.reviewId > highestId){
                                highestId = thisReview.reviewId + 1;
                            }
                            console.log("after",highestId,thisReview.reviewId);
                        });
                    })
                    .then(()=>{
                        this.reviews.reviewId = highestId;
                        let now = this.getCurrentDate();
                        this.reviews.date = now;
                        console.log(now)
                        db.collection("Reviews")
                            .add(this.reviews)
                            .then(() => {
                                this.$router.push("/");
                                console.log("got here inside the add to reviews")
                            })
                            .catch((error) => {
                                console.log("got this error",error);
                            });
                    })
                    .catch();
            },
            getCurrentDate(){
                let today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = today.getFullYear();

                today = mm + '/' + dd + '/' + yyyy;
                return today;
            }
        }

    }
</script>

<style scoped>

</style>