<template>
    <footer class="container">
        <div class="row bg-secondary">
            <div class="container-fluid pt-3 bg-secondary text-right col-lg-5">
                <div>
                    <h4>Martins Details</h4>
                    <p class="small m-0">Martin@martinsDetails.com</p>
                    <p class="small m-0">851 Plank Road, Menasha, WI </p>
                    <p class="small m-0">920-279-0657</p>
                    <p class="small m-0">©Copyright 2020 Martins Details </p>
                </div>
            </div>

            <div class="container pt-3 col-lg-5">
                <h4 class="m-0 p-0">Sitemap</h4>
                <ul class="mr-auto navbar-text p-0 m-0">
                    <li class="nav-item m-0">
                        <router-link to="/" class="nav-link text-black-50 p-0">Home</router-link>
                    </li>
                    <li class="nav-item m-0">
                        <router-link to="/gallery" class="nav-link text-black-50 p-0">Gallery</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/services" class="nav-link text-black-50 p-0">Packages</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/contact" class="nav-link text-black-50 p-0">Contact</router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-black-50 p-0" href="https://www.facebook.com/Martins-Details-114367500301912/about/?ref=page_internal">facebook</a>
                    </li>
                </ul>
            </div>
        </div>
            <p class="small text-center text-success">Page Developed by Neal Howard</p>

    </footer>
</template>

<script>

    export default {
        name: "footer"
    }
</script>

<style scoped>

</style>