<template>
    <div class="container-fluid bg-warning">
        <p class="small align-text-top m-0 p-0">Time Required: {{time}}</p>
        <p class="small m-0 ">Price:</p>
        <p class="small m-0 text-center">Cars: {{carcost}}</p>
        <p class="small m-0 text-center">Trucks and SUV's - {{truckcost}}</p>
        <p class="small m-0 text-center">{{notes}}</p>
    </div>
</template>

<script>
    export default {
        name: "det",
        props:{
            time: {
                type: String,
                default: "NaN"
            },
            carcost: {
                type: String,
                default: "NaN"
            },
            truckcost: {
                type: String,
                default:"NaN"
            },
            notes:{
                type: String,
                default:""
            }
        }
    }
</script>

<style scoped>

</style>
