<template>
    <div>
        <img class="card-img-top d-block d-none" src="../img/Lexus.jpg" alt="Gray lexus">
        <h1 class="text-center text-danger" >Gallery</h1>
        <div class="card col-12" onload="created()">

        </div>
        <div class="container">
            <div class="row" >
                <div v-for="image in images" :key="image.id" class="col-lg-3 col-md-4 col-sm-6">
                    <img :src=image.imageURL class="img-thumbnail" :alt="image.imageName">
                    <p>{{image.imageCaption}}</p>
                </div>
                <div v-if="userRole === 'admin'">
                    <router-link to="/addImage" class="btn btn-primary mt-2">Add Image</router-link><br>
                    <router-link to="/removeImage" class="btn btn-primary mt-3">Delete Image</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {db} from "../main";
    // import firebase from "firebase";

    export default {

        data(){
            return{
                images: [],
                userRole: "",
            }
        },
        created() {
            db.collection("Images").onSnapshot((snapshotChange) => {
                this.images=[];
                snapshotChange.forEach((doc) => {
                    this.images.push({
                        galleryIdNumber: doc.data().galleryIdNumber,
                        imageCaption: doc.data().imageCaption,
                        imageId: doc.data().imageId,
                        imageName: doc.data().imageName,
                        imageURL: doc.data().imageURL
                    })
                })
            });

        },
        mounted(){
            this.userRole=this.$route.params.userRole
        },
        name: "Gallery",
        methods:{

        }
    }
</script>

<style scoped>

</style>