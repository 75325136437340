<template>
    <div class="mb-5">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                   aria-selected="true">User Management</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                   aria-selected="false">Images</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
                   aria-selected="false">Contacts</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="Reviews-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews"
                   aria-selected="false">Reviews</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <user-manager class="m-5" ></user-manager>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="col-12 row">
                    <AddImage class="col col-lg-5 col-sm-12"></AddImage>
                    <RemoveImage class="col col-lg-5 col-sm-12"></RemoveImage>
                </div>
            </div>
            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <contact-requests></contact-requests>
            </div>
            <div class="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews">
                <h1 class="text-danger">This is your review manager page</h1>
                <input type="submit" class="mt-3 btn btn-primary" value="Delete Review" v-on:click="removeReview"/>

                <div v-for="review in reviews" :key="review.reviewId" class="card col-lg-4">
                    <div class="card-body">
                        <h4 class="card-title">{{review.reviewerName}}:</h4>
                        <p class="card-text">{{review.reviewContent}}</p>
                        <h5 class="card-text text-right">Review Id Number: {{review.reviewId}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserManager from "@/components/user-manager";
    import AddImage from "@/views/AddImage";
    import RemoveImage from "@/views/RemoveImage";
    import ContactRequests from "@/components/contact-requests";
    import {db} from "../main";
    // import firebase from "firebase";

    export default {
        name: "Admin",
        components: {ContactRequests, AddImage, UserManager,RemoveImage},
        data(){
            return{
                reviews: []
            }
        },
        created() {

            db.collection("Reviews").onSnapshot((snapshotChange) => {
                this.reviews = [];
                snapshotChange.forEach((doc) => {
                    this.reviews.push(
                        {
                            reviewContent: doc.data().reviewContent,
                            reviewId: doc.data().reviewId,
                            reviewerName: doc.data().reviewerName,
                            ReviewUserId: doc.data().ReviewUserId,
                        }
                    );
                })
            });
        },
        methods:{
            removeReview(){
                var tempId = prompt ("What is the id of the image you want to delete");
                let fullId ="";
                db.collection("Reviews")
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            if (tempId == doc.data().reviewId){
                                fullId = doc.id;
                            }
                        })

                    })
                    .then(()=>{
                        db.collection("Reviews")
                            .doc(fullId)
                            .delete()
                            .catch(error => console.log("Error message",error))
                    })

            }
        }
    }
</script>

<style scoped>

</style>