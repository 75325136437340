<template>
    <!--todo create dropdown for signin/register or logout-->
    <div class="container d-flex justify-content-center">
        <div class="card mx-5 my-5">
            <div class="card-body py-2 px-2">
                <h2 class="card-heading py-3 px-5">Log In</h2>
                <div class="row rone mx-3 my-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputEmail"  class="sr-only">Email</label>
                            <input type="email" class="form-control" v-model="email" id="inputEmail" placeholder="Email or Phone">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="inputPassword" class="sr-only">Password</label>
                            <input type="password" class="form-control" id="inputPassword" v-model="password" placeholder="Password">
                        </div>
                    </div>
                </div>
                <div class="row two mx-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mb-2" @click="login" >log In</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <a href="#" class="forgot">Forgot your Password?</a>
                        </div>
                    </div>
                </div>
                <div class="row text-center">
                    <p class="ml-5">Not a member yet?</p>
                    <router-link to="/register" class="btn btn-primary m-2">Registration</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import firebase from "firebase";

    export default {
        data(){
            return{
                email: "",
                password: "",
                loginErrorMessage: ""
            };
        },
        methods: {
            login() {
                this.loginErrorMessage = "";
                if (this.email && this.password) {
                    firebase
                        .auth()
                        .signInWithEmailAndPassword(this.email, this.password)
                        .then((userCredential) => {
                            console.log(
                                "SIGNED IN WITH EMAIL AND PASSWORD",
                                userCredential.user
                            );
                            // if you don't use a param then it won't compile!!!!
                        })
                        .catch((error) => {
                            this.loginErrorMessage = error.message;
                        });
                } else {
                    if (!this.email && !this.password) {
                        this.loginErrorMessage = "You must enter an email and password";
                    } else if (!this.email) {
                        this.loginErrorMessage = "You must enter an email";
                    } else if (!this.password) {
                        this.loginErrorMessage = "You must enter a password";
                    }
                }
            },
            googleLogin() {
                const provider = new firebase.auth.GoogleAuthProvider();
                firebase
                    .auth()
                    .signInWithPopup(provider)
                    .then((userCredential) => {
                        console.log("LOGGED IN W GMAIL:", userCredential.user);
                    })
                    .catch((error) => console.log(error));
            },
        },
        name: "SignIn",

    }

</script>

<style scoped>

</style>