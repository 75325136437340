import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../src/views/Home";
import Gallery from "../src/views/Gallery.vue"
import Contact from "../src/views/Contact.vue";
import Services from "../src/views/Services.vue";
import SignIn from "@/views/SignIn";
import Reviews from "@/views/Reviews";
import AddImage from "@/views/AddImage";
import RemoveImage from "@/views/RemoveImage";
import Admin from "@/views/Admin";
import Register from "@/views/Register";
// import TestingPage from "@/views/TestingPage";


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Home,
        name: "home",
        meta:{
            auth:true,
            title:'Martins Details'
        }
    },
    { path: "/gallery",
        component: Gallery,
        name: "Gallery",
        meta:{
            auth:true,
            title:'Martins Details-Gallery'
        }
    },
    { path: "/contact",
        component: Contact,
        name: "Contact",
        meta:{
            auth:true,
            title:'Martins Details-Contact'
        }
    },
    { path: "/services",
        component: Services,
        name: "Services",
        meta:{
            auth:true,
            title:'Martins Details-Packages'
        }
    },
    {
        path: "/login",
        component: SignIn,
        name: "SignIn",
        meta:{
            auth:true,
            title:'Martins Details-Log In'
        }
    },
    {
        path: "/reviews",
        component: Reviews,
        name: "Reviews",
        meta:{
            auth:true,
            title:'Martins Details-Reviews'
        }
    },
    {
        path: "/addImage",
        component: AddImage,
        name: "AddImage",
        meta:{
            auth:true,
            title:'Martins Details-Add Image'
        }
    },
    {
        path: "/removeImage",
        component: RemoveImage,
        name: "RemoveImage",
        meta:{
            auth:true,
            title:'Martins Details-Remove Image'
        }
    },
    {
        path: "/admin",
        component: Admin,
        name: "Admin",
        meta:{
            auth:true,
            title:'Martins Details-Admin Page'
        }
    },
    {
        path: "/register",
        component: Register,
        name: "Register",
        meta:{
            auth:true,
            title:'Martins Details-Register'
        }
    },


    // { path: "/testing", component: TestingPage, name: "Testing Page"}

];

const router = new VueRouter({
    mode: 'history',
    routes,
    base: "/js2/MartinsDetails/"
});

export default router;
