<template>
    <div>
        <h1 class="text-center text-danger" >Add Image</h1>

        <form @submit.prevent="addImage">
            <div class="form-group col-6">
                <label for="imageCaption">*Image Caption:</label>
                <input type="text" class="form-control" placeholder="Caption" name="First Name" id="imageCaption" v-model="image.imageCaption">
            </div>

            <div class="form-group col-6">
                <label for="imageName">*Image Name:</label>
                <input type="text" class="form-control" placeholder="Image Name" name="Image Name" id="imageName" v-model="image.imageName">
            </div>

            <div class="form-group col-6">
                <input type="file" class="mt-3 btn btn-primary" value="Browse" v-on:change="uploadImage($event)" />
            </div>
<!--            <div class="form-group col-6">-->
<!--                <input type="submit" class="mt-3" value="Submit" onclick="addImage()"/>-->
<!--            </div>-->
        </form>
    </div>
</template>

<script>
    import { db } from "../main";
    import firebase from "firebase";

    export default {
        name: "AddImage",
        data(){
            return {
                image:{}
            };
        },
        methods:{
            // addImage(e){
            //     e.preventDefault();
            //     let highestId = 0;
            //     db
            //         .collection("Images")
            //         .get()
            //         .then(querySnapshot => {
            //             querySnapshot.forEach(doc => {
            //                 const img = doc.data();
            //                 if (img.imageId > highestId){
            //                     highestId = img.id + 1;
            //                 }
            //             });
            //         })
            //         .then(()=>{
            //             this.image.imageId = highestId;
            //             db.collection("Images")
            //                 .add(this.image)
            //                 .then(() => {
            //                     this.$router.push("/gallery");
            //                     console.log(this.image.imageURL)
            //                 })
            //                 .catch((error) => {
            //                     console.log(error);
            //                 });
            //         })
            //         .catch();
            // },

            //uploadImage has to run first.  All as part of submit.  Browse.onchange should only update the temp image
            //file object.  Image file object is not being set correctly
            //------fixed--------
            uploadImage(evt){
                evt.preventDefault();
                //add image to storage
                const file = evt.target.files[0];
                const storageRef = firebase.storage().ref();
                const imgRef = storageRef.child(file.name);
                // Upload the file to storage
                const task = imgRef.put(file);
                task
                .then(() => {
                    imgRef.getDownloadURL()
                        //update image.imageURL
                        .then((url) => {
                        this.image.imageURL = url;
                        // console.log("added this file: ", file," at:", url);
                        alert("image added to storage!")
                        console.log("This Was Added to storage", this.image)
                        })
                        .then(() =>{
                        let highestId = 0;
                        db   //get new imageId
                            .collection("Images")
                            .get()
                            .then(querySnapshot => {
                                querySnapshot.forEach(doc => {
                                    const img = doc.data();
                                    console.log("before evaluation(highest,current)",highestId,img.imageId);
                                    if (img.imageId >= highestId){
                                        console.log("evaluating higher id")
                                        highestId = img.imageId + 1;
                                    }
                                    console.log("after evaluation(highest,current)",highestId,img.imageId);
                                });
                            })//add image object to the database
                            .then(()=>{
                                this.image.imageId = highestId;
                                console.log("highest id to be used: ", highestId)
                                db.collection("Images")
                                    .add(this.image)
                                    .then(() => {
                                        alert("database updated")
                                        this.$router.push("/gallery");
                                    })
                                    .catch((error) => {
                                        alert(error);
                                    });
                            })
                            .catch();
                        })
                })
                .catch(error => alert("Image did not upload: " + error))
            }
        }
    }
</script>

<style scoped>

</style>