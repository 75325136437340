<template>
  <div id="app">
      <navibar @test="userCheck"></navibar>
      <router-view />
      <Footer></Footer>
  </div>
</template>

<script>
import Navibar from "./components/navibar";
import Footer from "@/components/footer";

export default {
  name: 'App',
    data(){
      return {
          role:""
      }
    },
  components: {
      Footer,
      Navibar,

    },
    methods:{
      userCheck(role){
          this.role=role
      }
    },
    watch: {
        '$route' (to) {
            document.title = to.meta.title || 'Your Website'
        }
    }

}
</script>

<style>

</style>
