<template>
        <div>
            <img class="card-img-top" src="../img/blackBMW/BMWHero.jpeg" style="height: auto" alt="Black BMW">
            <h1 class="text-center text-danger" >Packages</h1>
            <div class="card col-12">

                <p>If you've been looking for the best car detailing that Winnebago County has to offer, then look no further.
                    Martins Details takes great pride in offering the highest quality products and services to our clients.
                    Treating every car like it was our own is the only way we operate. We offer several different packages to
                    it your needs.</p>
            </div>
            <div class="row p-3">
                <div class="card col-lg-3 pt-5" >
                    <img class="card-img-top" src="../img/Mustang_front.jpg" style="height: 200px" alt="Red Mustang">
                    <div class="card-body">
                        <div class="row">
                            <h4 class="text-left">Shine and go!</h4>
                        </div>
                        <p class="card-text">Our Basic package offers Hand Wash, interior cleaning and vacuum, tire dressing,
                            paint sealant </p>
                    </div>
                        <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#shineNgo">What To Expect</button>

                        <!-- Modal -->
                        <div id="shineNgo" class="modal fade" role="dialog">
                            <div class="modal-dialog">

                                <!-- Modal content-->
                                <div class="modal-content">
                                    <div class="modal-header bg-secondary">
                                        <h4 class="modal-title">What to expect</h4>
                                        <button type="button" class="close text-right" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <p>We will use the best car shampoos to foam and wash your vehicle with the best
                                            microfiber towels available to clean your car without inducing swirls  or
                                            scratches.  Your wheels and and tires will be hand cleaned and tire shine
                                            applied.  Paint protection will ensure your paint is protected and hydrophobic
                                            while enhancing the gloss of your vehicle.  The interior will be spot cleaned,
                                            wiped down, and interior glass cleaned for safer driving.  The safest and
                                            most gentle car wash anywhere!</p>
                                        <p>Time Allotted: 2 hrs</p>
                                    </div>
                                    <div class="modal-footer bg-secondary">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    <div class="card-footer">
                        <det time="2 Hours" carcost="$100" truckcost="$125"></det>
                    </div>
                </div>
                <div class="card col-lg-3 pt-5" >
                    <img class="card-img-top" src="../img/Highlander_Interior_Packages.jpeg" style="height: 200px" alt="Black corvette">
                    <div class="card-body">
                        <h4>Ultimate Clean and Shine</h4>
                        <p class="card-text">Our Executive package offers Hand wash, interior detail and deep
                            cleaning, hand wax, paint sealant, tire dressing, engine bay cleaning</p>
                    </div>
                    <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#ultimate">What To Expect</button>

                    <!-- Modal -->
                    <div id="ultimate" class="modal fade" role="dialog">
                        <div class="modal-dialog">

                            <!-- Modal content-->
                            <div class="modal-content">
                                <div class="modal-header bg-secondary">
                                    <h4 class="modal-title">What to expect</h4>
                                    <button type="button" class="close text-right" data-dismiss="modal">&times;</button>
                                </div>
                                <div class="modal-body">
                                    <p>We will use the best car shampoos to foam and wash your vehicle with the best
                                        microfiber  towels available to clean your car without inducing swirls or scratches.
                                        Chemical decontamination of your paint to help remove imbedded contaminants on
                                        your paint without inducing scratches or swirls.  Your wheels and tires will be
                                        hand cleaned.  The rims  will be protected for extra shine and ease of cleaning.
                                        Tire shine will be applied for the protection of the rubber and a clean look.
                                        Your car will then be finished with ceramic paint sealant for protection of your
                                        paint and a hand carnuba wax to enhance the glow and depth of the paint as an extra
                                        layer of protection from the elements.  The interior will be vacuumed thoroughly,
                                        and shampooed if needed.  Cloth mats will be detailed, rubber mats will be cleaned
                                        and protected.    All surfaces wiped down and protected.  Interior glass cleaned
                                        for safer driving.  Leather cleaned and protected if applicable.  The Ultimate Car
                                        wash experience!
                                        </p>

                                </div>
                                <div class="modal-footer bg-secondary">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="card-footer">
                        <det time="4-7 Hours" carcost="$200" truckcost="$250"></det>
                    </div>
                </div>
                <div class="card col-lg-3 pt-5" >
                    <img class="card-img-top"  src="../img/Corvette_1.jpeg" style="height: 200px" alt="Black Corvette">
                    <div class="card-body">
                        <h4>Stage 1 Paint Correction Detail</h4>
                        <p class="card-text">Hand wash, paint decontamination, clay bar, one step paint correction,
                            interior detail and deep cleaning, hand wax, paint sealant, tire dressing, engine bay cleaning</p>
                    </div>
                    <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#stageOne">What To Expect</button>

                    <!-- Modal -->
                    <div id="stageOne" class="modal fade" role="dialog">
                        <div class="modal-dialog">

                            <!-- Modal content-->
                            <div class="modal-content">
                                <div class="modal-header bg-secondary">
                                    <h4 class="modal-title">What to expect</h4>
                                    <button type="button" class="close text-right" data-dismiss="modal">&times;</button>
                                </div>
                                <div class="modal-body">
                                    <p>We will use the best car shampoos to foam and wash your vehicle with the best
                                        microfiber  towels available to clean your car without inducing swirls or scratches.
                                        Chemical decontamination of your paint to help remove imbedded contaminants on your
                                        paint without inducing scratches or swirls.  Clay bar treatment will remove all
                                        other surface contaminants.  A one step paint correction will remove up to 65% of
                                        all swirls and light scratches. Your wheels and tires will be hand cleaned.  The
                                        rims  will be protected for extra shine and ease of cleaning.  Tire shine will
                                        be applied for the protection of the rubber and a clean look.  Your car will then
                                        be finished with ceramic paint sealant for protection of your paint and a hand
                                        carnuba wax to enhance the glow and depth of the paint  as an extra layer of
                                        protection from the elements.  The interior will be vacuumed thoroughly, and
                                        shampooed if needed.  Cloth mats will be detailed, rubber mats will be cleaned
                                        and protected.    All surfaces wiped down and protected.  Interior glass cleaned
                                        for safer driving.  Leather cleaned and protected if applicable. </p>
                                    <p class="bg-warning">Make it a two step correction:  +$200  (will add ½-1 day to job time)</p>
                                    <p class="bg-warning">Add ceramic coating with Gtechniq Crystal Serum Light and EXO v4 for $500
                                        (will add 1-2 days to job time)</p>
                                </div>
                                <div class="modal-footer bg-secondary">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer">
                        <det time="1-2 Days" carcost="$500" truckcost="$575" ></det>
                    </div>
                </div>

                <div class="card col-lg-3 pt-5" >
                    <img class="card-img-top" src="../img/Mustang_engine_Packages.jpeg" style="height: 200px" alt="Car engine">
                    <div class="card-body">
                        <h4>The Martin Detail</h4>
                        <p class="card-text">The Martin Detail package offers the ultimate in car pampering. Includes Hand wash,
                            paint decontamination, clay bar, compounding, polishing, paint / rock chip fix, interior detail and
                            deep cleaning, engine bay cleaning, wheel polishing and dressing, exhaust tip polish, ceramic coating
                            with GTechniq Crystal Serum Light and EXOv4</p>
                    </div>
                    <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#theMartin">What To Expect</button>

                    <!-- Modal -->
                    <div id="theMartin" class="modal fade" role="dialog">
                        <div class="modal-dialog">

                            <!-- Modal content-->
                            <div class="modal-content">
                                <div class="modal-header bg-secondary">
                                    <h4 class="modal-title">What to expect</h4>
                                    <button type="button" class="close text-right" data-dismiss="modal">&times;</button>
                                </div>
                                <div class="modal-body">
                                    <p>The ultimate in car care!  This package is generally reserved for those that crave
                                        perfection!  Suggested for those that have a show car or need a good restoration
                                        of the paint.
                                        We will use the best car shampoos to foam and wash your vehicle with the best
                                        microfiber  towels available to clean your car without inducing swirls or scratches.
                                        Chemical decontamination of your paint to help remove imbedded contaminants on
                                        your paint without inducing scratches or swirls.  Clay bar treatment will remove
                                        all other surface contaminants.  A three step paint correction will remove up to
                                        95% of all swirls and light scratches. Your wheels and tires will be hand cleaned.
                                        The rims will be removed, cleaned, and protected with a wheel ceramic coating
                                        for extra shine and ease of cleaning.  Tire shine will be applied for the protection
                                        of the rubber and a clean look.  Your car will then be finished with Gtechniq
                                        Crystal Serum Light and two coats of EXOv4 for ultimate protection against the
                                        elements for up to 5 years.  The interior will be vacuumed thoroughly, and shampooed
                                        if needed.  Cloth mats will be detailed and protected, rubber mats will be cleaned
                                        and protected.    All surfaces wiped down and protected.  Interior glass cleaned
                                        for safer driving.  Leather cleaned and protected if applicable.  Cloth seats
                                        will be protected with Smart fabric protection.  The engine bay will be thoroughly
                                        detailed and exhaust tips polished if applicable.  Windshield will be covered
                                        with a one year coating.  Custom options available upon request.</p>
                                </div>
                                <div class="modal-footer bg-secondary">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer">
                        <det time="5-7 Days" carcost="$2200" truckcost="$2500"></det>
                    </div>
                </div>
            </div>

            <div class="card col-12 bg-info mb-3 text-center">
                <div class="text-center">
                    <h4 class="text-center">A'la Carte Options</h4>
                    <p class="text-center">Hand wash. $50 (1 hr)</p>
                    <p class="text-center">Interior cleaning and vacuum. $60 (1 hr)</p>
                    <p class="text-center">Interior detail and deep cleaning. $150 (3 hrs)</p>
                    <p class="text-center">Paint sealant or hand wax and tire dressing $65 </p>
                    <p class="text-center">Clay bar treatment $40</p>
                    <p class="text-center">Engine bay cleaning $50 (1 hr)</p>
                    <p class="text-center">Exhaust tip polish. $25 (1/2 hr)</p>
                    <p class="text-center">1 Year Glass Coating (1 hr)</p>
                </div>
            </div>

        </div>

</template>

<script>
    import det from "@/components/det";
    export default {
        components: {det},
        name: "Services"
    }
</script>

<style scoped>

</style>
