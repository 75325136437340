

import Vue from 'vue'
import App from './App'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import firebase from "firebase/app";
import 'firebase/firestore'
import 'font-awesome/fonts/fontawesome-webfont.eot';

// Your web app's Firebase configuration
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCkR8nLEqGTJM5NuscP66tiR7c0MfJ-JPk",
  authDomain: "final-project-44349.firebaseapp.com",
  databaseURL: "https://final-project-44349.firebaseio.com",
  projectId: "final-project-44349",
  storageBucket: "final-project-44349.appspot.com",
  messagingSenderId: "374408733469",
  appId: "1:374408733469:web:55591af4bb15770cb437f4"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();


Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');