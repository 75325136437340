<template>
    <div class="mb-5">
        <h1 class="text-center text-danger">These are all of the people who have tried to contact you</h1>

    <div>
        <div v-for="contact in contacts" :key="contact.id" class="row ">
            <customer-card :first-name="contact.firstName" :last-name="contact.lastName" :email="contact.email" :comments="contact.comments" :package-type="contact.packageType" :phone="contact.phoneNumber"></customer-card>
        </div>
    </div>


    </div>
</template>

<script>
    import {db} from "../main";
    import CustomerCard from "@/components/customer-card";
    export default {
        name: "contact-requests",
        components: {CustomerCard},
        data(){
            return{
                contacts: []
            }
        },
        created(){
            db.collection("Users").onSnapshot((snapshotChange) => {
                this.contacts=[];
                snapshotChange.forEach((doc) => {
                    this.contacts.push({
                        email: doc.data().email,
                        firstName: doc.data().firstName,
                        id: doc.data().id,
                        lastName: doc.data().lastName,
                        packageType: doc.data().packageType,
                        phoneNumber: doc.data().phoneNumber,
                        comments: doc.data().comments
                    })
                })
            });
        }
    }
</script>

<style scoped>

</style>