<template>
    <div>

        <div>
            <div class="row  pb-3">
                <div class="col-12">
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#carouselExampleControls" data-slide-to="0" class="active"></li>
                            <li data-target="#carouselExampleControls" data-slide-to="1"></li>
                            <li data-target="#carouselExampleControls" data-slide-to="2"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100 " src="../img/Corvette_Cropped.jpg" alt="First slide">
                                <div class="carousel-caption d-none d-md-block">
                                    <h1>Exterior Detailing</h1>
                                    <p>Hand washed using only the best products to pamper your car</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="../img/ToyotaTundra.jpg" alt="Second slide">
                                <div class="carousel-caption d-none d-md-block">
                                    <h1>Full Interior Detailing</h1>
                                    <p>Carpet cleaning and leather restoration will make your cars interior look better than new</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="../img/Mustang_New_Engine.jpg" alt="Third slide">
                                <div class="carousel-caption d-none d-md-block">
                                    <h1>Engine Bay Cleaning</h1>
                                    <p>Show off your engine to your friends</p>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <div>
            <div class="jumbotron">
                <h1>Welcome</h1>
                <div class="row">
                    <p class="col-lg-8">Martin's Details of Menasha is Winnebago County's premier auto detailing detailer. Owner/Operator Marty Veidins
                        ensures the highest quality detailing experience a customer could ask for. Offering a wide range of detailing
                        services from a basic wash and wax to paint correction and carpet cleaning. If you want to have your car
                        looking better than new, contact us to set up an appointment today. Thank you for choosing Martins Details.</p>
                    <div class="card col-lg-3">
                        <div class="card-body">
                            <h4 class="card-title">Get your vehicle done today!</h4>
                            <p class="card-text">Contact us to set up an appointment for any of our packages</p>
                            <a><router-link to="/services" class="btn btn-primary mr-2">Packages</router-link></a>
                            <a><router-link to="/contact" class="btn btn-primary">Contact us</router-link> </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="jumbotron">
                <h1 class="bg-primary rounded p-4 btn-primary">Here's What Our Customers Are Saying</h1>
                <div class="row bg-secondary">

                    <div v-for="review in reviews" :key="review.reviewId" class="card col-lg-4 rounded border-dark">
                        <div class="card-body">
                            <h4 class="card-title">
                                {{review.reviewerName}}:
                                <br>
                                <blockquote class="text-muted " style="font-size: small" >{{review.date}}</blockquote>
                            </h4>
                            <p class="card-text">{{review.reviewContent}}</p>
                        </div>
                    </div>
                    <div class="card col-lg-4">
                        <div class="card-body" v-if="currentUser">
                            <router-link to="/reviews" class="btn btn-primary">Leave a Review</router-link>
                        </div>
                        <div v-else class="card-body">
                            <router-link to="/login" class="btn btn-primary">Sign in to leave a review</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import {db} from "../main";
    import firebase from "firebase";
    export default {
        name: "Home",

        data(){
            return{
                reviews: [],
                currentUser:""
            }
        },
        created(){

            db.collection("Reviews").onSnapshot((snapshotChange) => {
                this.reviews=[];
                snapshotChange.forEach((doc) => {
                    this.reviews.push({
                        date: doc.data().date,
                        reviewContent: doc.data().reviewContent,
                        reviewId: doc.data().reviewId,
                        reviewerName: doc.data().reviewerName,
                        ReviewUserId: doc.data().ReviewUserId,
                        }
                    );
                })
            });
            firebase.auth()
                .onAuthStateChanged((user) => {
                    if (user) {
                        this.currentUser = user;
                        // a user has just logged in, so we need to get his/her document
                        // from our users collection
                        this.getUserDocument(user.uid);
                        this.$router.push("/").catch(() => {});
                    } else {
                        this.currentUser = null;
                        this.userRole = null;
                    }
                });
        },
        methods:{
            getUserDocument(userId) {
                const db = firebase.app().firestore();
                db.collection("users")
                    .doc(userId)
                    .get()
                    .then((doc) => {
                        if (doc.exists == false) {
                            console.log("THE USER DOCUMENT DOES NOT EXIST...");
                            this.createUserDocument();
                        } else {

                            this.userRole = doc.data().role;
                            this.$emit("test",this.userRole);
                        }
                    })
                    .catch((error) => console.log(error));
            },
        }
    }
</script>

<style scoped>

</style>