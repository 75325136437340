<template>
    <div class="card m-auto" style="width: 18rem;">
        <h4 class="ml-2">{{firstName}} {{lastName}}</h4>
        <div class="card-body">
            <h5 class="card-title">Phone: {{phone}}</h5>
            <p class="card-text">Package Type:{{packageType}}</p>
            <p class="card-text">Comments: {{comments}}</p>
            <p class="card-text">Email: {{email}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "customer-card",
        props:{
            firstName: String,
            lastName: String,
            email: String,
            phone: String,
            comments: String,
            packageType: String
        }
    }
</script>

<style scoped>

</style>