<template>
    <div>
        <img class="card-img-top" src="../img/chrysler_300.jpg" style="height: auto" alt="Cool looking car">
        <h1 class="text-center text-danger" >Contact Us</h1>

<!--this action was in the form attributes-->
<!--        -->

        <form @submit.prevent="addUser" action="mailto:Martin@MartinsDetails.com?subject=myform_submission" method="post" enctype="text/plain">
            <div class="form-group col-6">
                <label for="myFName">*First Name:</label>
                <input type="text" class="form-control" placeholder="John" name="First Name" id="myFName" v-model="user.firstName" required>
            </div>

            <div class="form-group col-6">
                <label for="myLName">*Last Name:</label><input type="text" class="form-control" placeholder="Doe" name="Last Name" id="myLName" v-model="user.lastName" required>
            </div>
            <div class="form-group col-6">
                <label for="email">Email address:</label>
                <input type="email" class="form-control" placeholder="Enter email" id="email" v-model="user.email" required>
            </div>
            <div class="form-group col-6">
                <label for="myPhone">*Phone Number:</label><input type="tel" class="form-control" placeholder="###-###-####" name="Phone" id="myPhone" maxlength="12" v-model="user.phoneNumber" required><br>
            </div>
            <div class="form-group col-6">
                <label for="reso">Package</label>
            </div>
            <div class="form-group col-6">
                <input type="text"  class="form-control" name="Requested Service" id="reso" list="times" v-model="user.packageType" required><br>
                <datalist id="times">
                    <option>The Martin Detail</option>
                    <option>The Premium</option>
                    <option>The Executive</option>
                    <option>The Basic</option>
                </datalist>
            </div>
            <div class="form-group col-6">
                <label for="myComments">Vehicle type</label>
                <textarea name="Vehicle Type" class="form-control" v-model="user.comments" id="myComments" rows="2" cols="30"></textarea>
                <input type="submit" class="mt-3" value="submit" onclick="addUser()"/>
            </div>
        </form>
    </div>
</template>

<script>
    import { db } from "../main";
    export default {
        data(){
            return{
                user:{}
            }
        },
        name: "Contact",

        methods:{
            //warning this method adds a new user even if they have been here before.
            // It might be better this way.  is that a way to track usage? Or should there be validation?
            //validation would be on the email
            addUser(e){
                e.preventDefault();
                let highestId = 0;
                db
                    .collection("Users")
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            const thisUser = doc.data();
                            console.log("before",highestId,thisUser.id);
                            if (thisUser.id > highestId){
                                highestId = thisUser.id + 1;
                            }
                            console.log("after",highestId,thisUser.id);
                        });
                    })
                    .then(()=>{
                        this.user.id = highestId;
                        db.collection("Users")
                            .add(this.user)
                            .then(() => {
                                this.$router.push("/");
                                console.log("got here")
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch();
            }
        }
    }


</script>

<style scoped>

</style>