<template>
    <div class="container">
        <h1 class="text-center text-danger" >Remove Image</h1>

        <div class="row" >
            <div v-for="image in images" :key="image.id" class="col-lg-3 col-md-4 col-sm-6">
                <img :src=image.imageURL class="img-thumbnail" :alt="image.name">
                <p>Image Id: {{image.imageId}}</p>
            </div>
            <div>
                <div class="form-group col-6">
                    <input type="submit" class="mt-3 btn btn-primary" value="Delete Image" v-on:click="removeImage"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {db} from "../main";
    export default {
        name: "RemoveImage",

        data(){
            return{
                images: [],
            }
        },
        created() {
            db.collection("Images").onSnapshot((snapshotChange) => {
                this.images = [];
                snapshotChange.forEach((doc) => {
                    this.images.push({
                        galleryIdNumber: doc.data().galleryIdNumber,
                        imageCaption: doc.data().imageCaption,
                        imageId: doc.data().imageId,
                        imageName: doc.data().imageName,
                        imageURL: doc.data().imageURL
                    })
                })
            })
        },
        methods:{
            removeImage(){
                var tempId = prompt ("What is the id of the image you want to delete");
                let fullId ="";
                db.collection("Images")
                    .get()
                    .then((querySnapshot)=>{
                        querySnapshot.forEach(doc =>{
                            const id = doc.data();
                            console.log("ImageID#, docID#",tempId,doc.id)
                            if(tempId == id.imageId){
                                console.log("inside if statement")
                                fullId = doc.id;
                                console.log("after ImageID#, docID#",tempId,doc.id)

                            }
                        })
                    })
                    .then(() =>{
                        db.collection("Images")
                            .doc(fullId)
                            .delete()
                            .catch(error => console.log(error))
            })

            }
        }
    }
</script>

<style scoped>

</style>