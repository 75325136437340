<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary p-2 border-bottom">
        <div class="container ">

            <router-link to="/" class="navbar-brand" >MARTIN'S DETAILS</router-link>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">

                    <li class="nav-item //active">
                        <router-link to="/" class="nav-link">Home</router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'Gallery',params:{userRole}}" class="nav-link">Gallery</router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/services" class="nav-link">Packages</router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/contact" class="nav-link">Contact</router-link>
                    </li>

<!--                    <li class="nav-item">-->
<!--                        <router-link to="/reviews" class="nav-link">Reviews</router-link>-->
<!--                    </li>-->
                    <li class="nav-item">
                        <a class="nav-link" href="https://www.facebook.com/Martins-Details-114367500301912"><i class="fab fa-facebook-f"></i>facebook</a>
                    </li>

                    <li class="nav-item text-right" v-if="currentUser" >
                        <a href="#" class="nav-link" @click.prevent="logout">Log out</a>
                    </li>
                    <li class="nav-item text-right" v-else>
                        <router-link to="/login" class="nav-link">Sign in</router-link>
                    </li>
                    <li class="nav-item text-right" v-if="userRole ==='admin' ">
                        <router-link :to="{name:'Admin',params:{userRole}}" class="nav-link" >Admin Page</router-link>
                    </li>
                    <li>
                        <span v-if="currentUser">
                        <b>You are logged in as: {{ currentUser.email }}</b>

                        </span>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
</template>

<script>
    import firebase from "firebase";

    export default {
        name: "navibar",
        data(){
            return {
                currentUser: null,
                userRole: null,
            };
        },
        mounted(){
            firebase.auth()
                .onAuthStateChanged((user) => {
                    if (user) {
                        this.currentUser = user;
                        // a user has just logged in, so we need to get his/her document
                        // from our users collection
                        this.getUserDocument(user.uid);
                        this.$router.push("/").catch(() => {});
                    } else {
                        this.currentUser = null;
                        this.userRole = null;
                    }
                });
        },
        methods: {
            logout() {
                firebase
                    .auth()
                    .signOut()
                    .then(
                        () => {
                            this.userRole = null;
                            this.$router.push("login").catch(() => {});
                            // when a user logs out, redirect to the login view
                            // note that if you don't put the catch(), then
                            // you may get a warning saying you should avoid redundant navigation
                            console.log("Logged Out");
                        },
                        (error) => {
                            console.log("error logging out", error);
                        }
                    );
            },
            getUserDocument(userId) {
                const db = firebase.app().firestore();
                db.collection("users")
                    .doc(userId)
                    .get()
                    .then((doc) => {
                        if (doc.exists == false) {
                            console.log("THE USER DOCUMENT DOES NOT EXIST...");
                            this.createUserDocument();
                        } else {
                            this.userRole = doc.data().role;
                            this.$emit("test",this.userRole);
                        }
                    })
                    .catch((error) => console.log(error));
            },
            createUserDocument() {
                const user = firebase.auth().currentUser;
                if (user) {
                    // Note, you may want to store more info in the users collection
                    // (I am just adding email and role, but your app may call for more fields)
                    const db = firebase.app().firestore();
                    this.userRole = "user"; // when a new user is created they default to the 'user' role
                    db.collection("users")
                        .doc(user.uid)
                        .set({ email: user.email, role: this.userRole }, { merge: true })
                        .then(() => console.log("USER DOCUMENT CREATED"))
                        .catch((e) => console.log(e));
                } else {
                    console.log("cannot create user doc!");
                }
            },
        },
    }
</script>

<style scoped>

</style>